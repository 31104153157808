import AxleHireIcon from "./icons/AxleHire.svg";
import DHLIcon from "./icons/DHL.svg";
import DoorDashIcon from "./icons/DoorDash.svg";
import FedExIcon from "./icons/FedEx.svg";
import FreightIcon from "./icons/Freight.svg";
import GLSIcon from "./icons/GLS.svg";
import JitsuIcon from "./icons/Jitsu.svg";
import LasershipIcon from "./icons/Lasership.svg";
import LSOIcon from "./icons/LSO.svg";
import OnTracIcon from "./icons/OnTrac.svg";
import PassportIcon from "./icons/Passport.svg";
import PCFIcon from "./icons/PCF.svg";
import TForceIcon from "./icons/TForce.svg";
import UDSIcon from "./icons/UDS.svg";
import UPSIcon from "./icons/UPS.svg";
import USPSIcon from "./icons/USPS.svg";
import VehoIcon from "./icons/Veho.svg";

export enum Carriers {
  AXLEHIRE = "AXLEHIRE",
  BETTERTRUCKS = "BETTERTRUCKS",
  DHL = "DHL",
  DHL_EXPRESS = "DHL_EXPRESS",
  DOORDASH = "DOORDASH",
  FEDEX = "FEDEX",
  FREIGHT = "FREIGHT",
  GLS = "GLS",
  JITSU = "JITSU",
  LASERSHIP = "LASERSHIP",
  LSO = "LSO",
  ONTRAC = "ONTRAC",
  OTHER = "OTHER",
  PASSPORT = "PASSPORT",
  PCF = "PCF",
  TFORCE = "TFORCE",
  UDS = "UDS",
  UPS = "UPS",
  USPS = "USPS",
  VEHO = "VEHO",
}

export const carrierNames: Record<Carriers, string> = {
  [Carriers.AXLEHIRE]: "AxleHire",
  [Carriers.BETTERTRUCKS]: "BetterTrucks",
  [Carriers.DHL]: "DHL",
  [Carriers.DHL_EXPRESS]: "DHL Express",
  [Carriers.DOORDASH]: "DoorDash",
  [Carriers.FEDEX]: "FedEx",
  [Carriers.FREIGHT]: "GlobalTranz",
  [Carriers.GLS]: "GLS",
  [Carriers.JITSU]: "Jitsu",
  [Carriers.LASERSHIP]: "LaserShip",
  [Carriers.LSO]: "LSO",
  [Carriers.ONTRAC]: "OnTrac",
  [Carriers.OTHER]: "Other",
  [Carriers.PASSPORT]: "Passport",
  [Carriers.PCF]: "PCF",
  [Carriers.TFORCE]: "TForce",
  [Carriers.UDS]: "UDS",
  [Carriers.UPS]: "UPS",
  [Carriers.USPS]: "USPS",
  [Carriers.VEHO]: "Veho",
};

export const carrierIcons: Record<Carriers, string> = {
  [Carriers.AXLEHIRE]: AxleHireIcon,
  [Carriers.BETTERTRUCKS]: FreightIcon,
  [Carriers.DHL]: DHLIcon,
  [Carriers.DHL_EXPRESS]: DHLIcon,
  [Carriers.DOORDASH]: DoorDashIcon,
  [Carriers.FEDEX]: FedExIcon,
  [Carriers.FREIGHT]: FreightIcon,
  [Carriers.GLS]: GLSIcon,
  [Carriers.JITSU]: JitsuIcon,
  [Carriers.LASERSHIP]: LasershipIcon,
  [Carriers.LSO]: LSOIcon,
  [Carriers.ONTRAC]: OnTracIcon,
  [Carriers.OTHER]: FreightIcon,
  [Carriers.PASSPORT]: PassportIcon,
  [Carriers.PCF]: PCFIcon,
  [Carriers.TFORCE]: TForceIcon,
  [Carriers.UDS]: UDSIcon,
  [Carriers.UPS]: UPSIcon,
  [Carriers.USPS]: USPSIcon,
  [Carriers.VEHO]: VehoIcon,
};

const AXLEHIRE_TRACKING_CODE_URL = "https://recipient.axlehire.com/";
const JITSU_TRACKING_CODE_URL = "https://tracking.gojitsu.com/";
const BETTERTRUCKS_TRACKING_CODE_URL = "https://tracking.bettertrucks.com/Tracking?ShipmentId=";
const DHL_EXPRESS_TRACKING_CODE_URL = "https://www.dhl.com/us-en/home/tracking.html?tracking-id=";
const DHL_TRACKING_CODE_URL = "https://webtrack.dhlecs.com/?trackingnumber=";
const FEDEX_TRACING_CODE_URL = "https://www.fedex.com/apps/fedextrack/?action=track&cntry_code=us&trackingnumber=";
const GLOBALTRANZ_TRACKING_CODE_URL = "https://carrierrate.globaltranz.com/PublicTrack/PublicTrack/#/Home";
const GLS_TRACKING_CODE_URL = "https://www.gls-us.com/track-and-trace?TrackingNumbers=";
const LASERSHIP_TRACKING_CODE_URL = "https://www.lasership.com/track.php?track_number_input=";
const LSO_TRACKING_CODE_URL = "https://www.lso.com/tracking?airbillnos=";
const ONTRAC_TRACKING_CODE_URL = "https://www.ontrac.com/trackingresults.asp?tracking_number=";
const PASSPORT_TRACKING_CODE_URL = "https://track.passportshipping.com/";
const PCF_TRACKING_CODE_URL = "https://pcfcorp.com/track-package/?trackingNumber=";
const TFORCE_TRACKING_CODE_URL = "https://direct.tfesg.com/finalmiletrack/Track?trackingNumber=";
const UDS_TRACKING_CODE_URL = "https://www.uniteddeliveryservice.com/track/barcode/";
const UPS_TRACKING_CODE_URL = "https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=";
const USPS_TRACKING_CODE_URL = "https://tools.usps.com/go/TrackConfirmAction?tLabels=";
const VEHO_TRACKING_CODE_URL = "https://track.shipveho.com/#/barcode/";

export const CARRIER_TRACKING_CODE_URLS: Partial<Record<Carriers, string>> = {
  [Carriers.AXLEHIRE]: AXLEHIRE_TRACKING_CODE_URL,
  [Carriers.BETTERTRUCKS]: BETTERTRUCKS_TRACKING_CODE_URL,
  [Carriers.DHL]: DHL_TRACKING_CODE_URL,
  [Carriers.DHL_EXPRESS]: DHL_EXPRESS_TRACKING_CODE_URL,
  [Carriers.FEDEX]: FEDEX_TRACING_CODE_URL,
  [Carriers.FREIGHT]: GLOBALTRANZ_TRACKING_CODE_URL,
  [Carriers.JITSU]: JITSU_TRACKING_CODE_URL,
  [Carriers.GLS]: GLS_TRACKING_CODE_URL,
  [Carriers.LASERSHIP]: LASERSHIP_TRACKING_CODE_URL,
  [Carriers.LSO]: LSO_TRACKING_CODE_URL,
  [Carriers.ONTRAC]: ONTRAC_TRACKING_CODE_URL,
  [Carriers.PASSPORT]: PASSPORT_TRACKING_CODE_URL,
  [Carriers.PCF]: PCF_TRACKING_CODE_URL,
  [Carriers.TFORCE]: TFORCE_TRACKING_CODE_URL,
  [Carriers.UDS]: UDS_TRACKING_CODE_URL,
  [Carriers.UPS]: UPS_TRACKING_CODE_URL,
  [Carriers.USPS]: USPS_TRACKING_CODE_URL,
  [Carriers.VEHO]: VEHO_TRACKING_CODE_URL,
};
