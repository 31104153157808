import { startOfDay } from "date-fns";
import moment from "moment-timezone";
import { formatDate } from "./formatDate";
import { isDateToday } from "./isDateToday";
import { DateFormat } from "./types";

export const LOCAL_TIMESTAMP_CARRIERS = ["UPS", "USPS", "UDS", "DHL_EXPRESS", "SANDBOX"];

export const convertCarrierDateToUTCDate = (date: Date, shippingMethod?: string, destinationTz?: string) => {
  // for some carriers, delivery dates are stored in the local timezone of the destination even though it is in UTC format, so we need to add offset back to actually treat it as UTC.
  if (!shippingMethod || !destinationTz) {
    return date;
  }
  const carrier = shippingMethod.split(".")[0];
  if (!LOCAL_TIMESTAMP_CARRIERS.includes(carrier)) {
    return date;
  }

  const offset = moment.tz(destinationTz).utcOffset();
  return new Date(new Date(date).getTime() + offset * 60000 * -1);
};

export const getShippedDate = (date: Date, destinationTz?: string) => {
  return isDateToday(date) ? `today` : formatDate(date, destinationTz, DateFormat.WeekMonthDay);
};

export const isUntilDate = (date: Date, untilDate: Date) => {
  return startOfDay(new Date(date)) <= startOfDay(new Date(untilDate));
};
