import { ParcelTrackingEventsResponse } from "@deliverr/parcel-client";
import { Status } from "~/common/types/Status";
import { formatDate, getDeliveredDate, isDateToday } from "~/common/utils/date";
import BasketIcon from "~/common/utils/order/icons/Basket.svg";
import PackageCancelledIcon from "~/common/utils/order/icons/PackageCancelled.svg";
import PackageOpenIcon from "~/common/utils/order/icons/PackageOpen.svg";
import PackageSealedIcon from "~/common/utils/order/icons/PackageSealed.svg";
import TruckIcon from "~/common/utils/order/icons/Truck.svg";

// KEEP THESE UP TO DATE WITH `ParcelClient ParcelTrackingEventTypes, ticket: https://flexport.atlassian.net/browse/UP-1321
export enum ParcelTrackingEventTypes {
  Created = "Created",
  Repurchased = "Repurchased",
  Cancelled = "Cancelled",
  PickedUpFromOrigin = "PickedUpFromOrigin",
  ArrivedAtOriginPort = "ArrivedAtOriginPort",
  DepartedFromOriginPort = "DepartedFromOriginPort",
  ArrivedAtDestinationPort = "ArrivedAtDestinationPort",
  CustomOnHold = "CustomOnHold",
  CustomUnknown = "CustomUnknown",
  CustomCleared = "CustomCleared",
  ArrivedAtSortCenter = "ArrivedAtSortCenter",
  DepartedFromSortCenter = "DepartedFromSortCenter",
  PickedUpByCarrier = "PickedUpByCarrier",
  InTransitByCarrier = "InTransitByCarrier",
  ReadyForDelivery = "ReadyForDelivery",
  Delivered = "Delivered",
  DeliveryFailed = "DeliveryFailed",
  FlexIdLinked = "FlexIdLinked",
  ReturnInitiated = "ReturnInitiated",
  ReturnInTransit = "ReturnInTransit",
  OutForReturn = "OutForReturn",
  ReturnCompleted = "ReturnCompleted",
}

export const UnifiedTrackingStatusText: Record<ParcelTrackingEventTypes, string> = {
  [ParcelTrackingEventTypes.Created]: "Order placed",
  [ParcelTrackingEventTypes.PickedUpFromOrigin]: "Picked up from origin",
  [ParcelTrackingEventTypes.ArrivedAtOriginPort]: "Arrived at Origin port",
  [ParcelTrackingEventTypes.DepartedFromOriginPort]: "Departed from Origin port",
  [ParcelTrackingEventTypes.ArrivedAtDestinationPort]: "Arrived at Destination port",
  [ParcelTrackingEventTypes.CustomOnHold]: "Waiting for customs",
  [ParcelTrackingEventTypes.CustomCleared]: "Customs cleared",
  [ParcelTrackingEventTypes.CustomUnknown]: "Custom Unknown",
  [ParcelTrackingEventTypes.ArrivedAtSortCenter]: "In Transit",
  [ParcelTrackingEventTypes.DepartedFromSortCenter]: "In Transit",
  [ParcelTrackingEventTypes.PickedUpByCarrier]: "Picked up by Carrier",
  [ParcelTrackingEventTypes.InTransitByCarrier]: "In Transit by Carrier",
  [ParcelTrackingEventTypes.ReadyForDelivery]: "In Transit",
  [ParcelTrackingEventTypes.Delivered]: "Delivered",
  [ParcelTrackingEventTypes.Cancelled]: "Cancelled",
  [ParcelTrackingEventTypes.Repurchased]: "Label repurchased",
  [ParcelTrackingEventTypes.DeliveryFailed]: "Delivery Failed",
  [ParcelTrackingEventTypes.ReturnInitiated]: "Return Initiated",
  [ParcelTrackingEventTypes.ReturnInTransit]: "Return In Transit",
  [ParcelTrackingEventTypes.OutForReturn]: "Out for Return",
  [ParcelTrackingEventTypes.ReturnCompleted]: "Return Completed",
  [ParcelTrackingEventTypes.FlexIdLinked]: "Flex ID Linked",
};

export const UnifiedTrackingStatusIcon: Record<ParcelTrackingEventTypes, string> = {
  [ParcelTrackingEventTypes.Created]: BasketIcon,
  [ParcelTrackingEventTypes.PickedUpFromOrigin]: PackageOpenIcon,
  [ParcelTrackingEventTypes.ArrivedAtOriginPort]: TruckIcon,
  [ParcelTrackingEventTypes.DepartedFromOriginPort]: TruckIcon,
  [ParcelTrackingEventTypes.ArrivedAtDestinationPort]: TruckIcon,
  [ParcelTrackingEventTypes.CustomOnHold]: PackageSealedIcon,
  [ParcelTrackingEventTypes.CustomCleared]: TruckIcon,
  [ParcelTrackingEventTypes.CustomUnknown]: TruckIcon,
  [ParcelTrackingEventTypes.ArrivedAtSortCenter]: TruckIcon,
  [ParcelTrackingEventTypes.DepartedFromSortCenter]: TruckIcon,
  [ParcelTrackingEventTypes.PickedUpByCarrier]: TruckIcon,
  [ParcelTrackingEventTypes.InTransitByCarrier]: TruckIcon,
  [ParcelTrackingEventTypes.ReadyForDelivery]: TruckIcon,
  [ParcelTrackingEventTypes.Delivered]: PackageSealedIcon,
  [ParcelTrackingEventTypes.Cancelled]: PackageCancelledIcon,
  [ParcelTrackingEventTypes.Repurchased]: PackageOpenIcon,
  [ParcelTrackingEventTypes.DeliveryFailed]: PackageCancelledIcon,
  [ParcelTrackingEventTypes.ReturnInitiated]: PackageOpenIcon,
  [ParcelTrackingEventTypes.ReturnInTransit]: TruckIcon,
  [ParcelTrackingEventTypes.OutForReturn]: TruckIcon,
  [ParcelTrackingEventTypes.ReturnCompleted]: PackageSealedIcon,
  [ParcelTrackingEventTypes.FlexIdLinked]: PackageSealedIcon,
};

export function getStatusFromUnifiedTrackingDetail(unifiedTrackingDetail: ParcelTrackingEventsResponse): Status {
  const estimatedDeliveryTime = unifiedTrackingDetail.estimatedDeliveryTime;
  const currentEvent = unifiedTrackingDetail.trackingHistory.length
    ? unifiedTrackingDetail.trackingHistory[unifiedTrackingDetail.trackingHistory.length - 1]
    : undefined;
  const status = currentEvent?.eventType ?? ParcelTrackingEventTypes.Created;
  const base = {
    heading: UnifiedTrackingStatusText[status],
    icon: UnifiedTrackingStatusIcon[status],
    isToday: false,
  };

  const estimatedByText = estimatedDeliveryTime
    ? `Estimated by ${formatDate(estimatedDeliveryTime, unifiedTrackingDetail?.destinationTimezone)}`
    : "Estimating delivery time...";

  switch (status) {
    case ParcelTrackingEventTypes.Created:
      return {
        ...base,
        background: "surface-neutral",
        body: estimatedByText,
        step: 0,
      };
    case ParcelTrackingEventTypes.PickedUpFromOrigin:
      return {
        ...base,
        background: "surface-highlight-subdued",
        body: estimatedByText,
        step: 1,
      };
    case ParcelTrackingEventTypes.ArrivedAtDestinationPort:
    case ParcelTrackingEventTypes.ArrivedAtOriginPort:
    case ParcelTrackingEventTypes.ArrivedAtSortCenter:
    case ParcelTrackingEventTypes.DepartedFromOriginPort:
    case ParcelTrackingEventTypes.DepartedFromSortCenter:
    case ParcelTrackingEventTypes.ReadyForDelivery:
    case ParcelTrackingEventTypes.PickedUpByCarrier:
    case ParcelTrackingEventTypes.InTransitByCarrier:
    case ParcelTrackingEventTypes.CustomCleared:
      const toDeliverToday = isDateToday(estimatedDeliveryTime!);

      return {
        ...base,
        background: "surface-highlight-subdued",
        body: estimatedByText,
        step: toDeliverToday ? 3 : 2,
        isToday: toDeliverToday,
      };

    case ParcelTrackingEventTypes.CustomOnHold:
      return {
        ...base,
        background: "surface-neutral",
        body: "",
        step: 2,
      };

    case ParcelTrackingEventTypes.Delivered:
      const deliveredToday = isDateToday(currentEvent!.eventTime);

      return {
        ...base,
        background: deliveredToday ? "surface-success-subdued" : "background",
        body: `Delivered ${getDeliveredDate(currentEvent!.eventTime, unifiedTrackingDetail?.destinationTimezone)}`,
        step: 5,
        isToday: deliveredToday,
      };
    case ParcelTrackingEventTypes.Cancelled:
      return {
        ...base,
        background: "background",
        body: `Cancelled ${formatDate(currentEvent!.eventTime, unifiedTrackingDetail?.destinationTimezone)}`,
        step: 0,
      };
    case ParcelTrackingEventTypes.Repurchased:
      return {
        ...base,
        background: "background",
        body: `Label re-purchased ${formatDate(currentEvent!.eventTime, unifiedTrackingDetail?.destinationTimezone)}`,
        step: 0,
      };
    case ParcelTrackingEventTypes.DeliveryFailed:
      return {
        ...base,
        background: "surface-critical",
        body: `Delivery Failed ${formatDate(currentEvent!.eventTime, unifiedTrackingDetail?.destinationTimezone)}`,
        step: 5,
      };
    case ParcelTrackingEventTypes.ReturnInitiated:
      return {
        ...base,
        background: "surface-attention",
        body: `Return Initiated on ${formatDate(currentEvent!.eventTime, unifiedTrackingDetail?.destinationTimezone)}`,
        step: 1,
      };
    case ParcelTrackingEventTypes.ReturnInTransit:
      return {
        ...base,
        background: "surface-attention",
        body: `Return In Transit on ${formatDate(currentEvent!.eventTime, unifiedTrackingDetail?.destinationTimezone)}`,
        step: 2,
      };
    case ParcelTrackingEventTypes.OutForReturn:
      return {
        ...base,
        background: "surface-attention",
        body: `Out for Return on ${formatDate(currentEvent!.eventTime, unifiedTrackingDetail?.destinationTimezone)}`,
        step: 3,
      };
    case ParcelTrackingEventTypes.ReturnCompleted:
      return {
        ...base,
        background: "surface-attention",
        body: `Return Completed on ${formatDate(currentEvent!.eventTime, unifiedTrackingDetail?.destinationTimezone)}`,
        step: 5,
      };
    default:
      return {
        ...base,
        background: "surface-critical",
        body: "Contact seller",
        step: 0,
      };
  }
}
