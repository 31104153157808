import { Carriers, CARRIER_TRACKING_CODE_URLS } from "./types";

export const getTrackingCodeUrl = (carrier: Carriers, trackingCode: string): string | undefined => {
  const carrierCode = carrier.toUpperCase() as Exclude<Carriers, Carriers.OTHER>;
  if (carrierCode === Carriers.FREIGHT) {
    return CARRIER_TRACKING_CODE_URLS[carrierCode];
  }

  const trackingCodeUrl = CARRIER_TRACKING_CODE_URLS[carrierCode];
  if (trackingCodeUrl) {
    return `${trackingCodeUrl}${trackingCode}`;
  }
};
