import { drop, flowRight, join, split, toLower, upperFirst } from "lodash/fp";
import { FulfillmentShipmentWithoutAddress, TrackingDetailResponse } from "~/common/types";
import { findShipmentAndPackage } from "../package/findPackage";

export const getShippingMethod = (
  trackingCode: string,
  trackingDetail: TrackingDetailResponse,
  shipments?: FulfillmentShipmentWithoutAddress[]
): string => {
  if (trackingDetail) {
    return trackingDetail.shippingMethod;
  }

  if (!shipments || shipments.length === 0) {
    return "";
  }

  const { pkg } = findShipmentAndPackage(shipments, trackingCode);

  return pkg ? pkg.shippingMethod : "";
};

export const parseShippingMethod = (shippingMethod: string): string => {
  const shipping = flowRight(upperFirst, toLower, join(" "), drop(1), split("."))(shippingMethod);
  return `${shipping} shipping`;
};
