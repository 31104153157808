import { Carriers } from "./types";

export const getCarrier = (shippingMethod: string): Carriers => {
  const carrier = shippingMethod.toUpperCase().split(".");

  const carrierCode =
    carrier[0] === "DHL" ? (carrier[1] === "EXPRESS" ? Carriers.DHL_EXPRESS : Carriers.DHL) : carrier[0];

  return Carriers[carrierCode] || Carriers.OTHER;
};
