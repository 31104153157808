import { datadogRum } from "@datadog/browser-rum";
import { Link, useNavigate, useParams } from "@remix-run/react";
import { Box, Inline, Text } from "@shopify/polaris";
import { ArrowLeftMinor } from "@shopify/polaris-icons";
import { Button } from "react-bootstrap";

import { PublicOrderSummary } from "common/types";
import { getOrderIdForDisplay } from "common/utils/order/getOrderIdForDisplay";

export interface TopBarProps {
  order: PublicOrderSummary;
  sellerUrl?: string;
  backUrl?: boolean;
}
const backButtonStyle = { borderRadius: "50%", width: 45, height: 45, border: "none", paddingY: "1em" };

export function TopBar({ order, sellerUrl, backUrl }: TopBarProps) {
  const { listingSolutionOrderId, businessName } = order;
  const { orderId } = useParams();
  const navigate = useNavigate();

  const handleNavigate = () => {
    datadogRum.addAction("user_navigate_back_from_tracking", { orderId });
    navigate(`/${orderId}`);
  };

  const handleSellerUrl = () => {
    datadogRum.addAction("user_click_seller_url", { sellerUrl });
  };

  const backButtonMarkup = backUrl ? (
    <Button variant="outline-light" onClick={handleNavigate} style={backButtonStyle}>
      <ArrowLeftMinor />
    </Button>
  ) : null;

  return (
    <Box borderBlockEnd="base" padding="5">
      <Inline wrap={false} align="space-between" blockAlign="baseline">
        <Inline wrap={false} gap="10" blockAlign="baseline">
          <Inline wrap={false} gap="1" blockAlign="baseline">
            {backButtonMarkup}
            <Text variant="bodyLg" as="span">
              {businessName!}
            </Text>
          </Inline>
          {listingSolutionOrderId && (
            <Text variant="bodyLg" as="span">
              Order #{getOrderIdForDisplay(listingSolutionOrderId)}
            </Text>
          )}
        </Inline>
        {sellerUrl && (
          <Link to={sellerUrl} className="btn btn-outline-dark" onClick={handleSellerUrl} target="_blank">
            Visit Store
          </Link>
        )}
      </Inline>
    </Box>
  );
}
