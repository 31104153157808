import { classNames } from "@shopify/css-utilities";
import React, { Suspense } from "react";
import { ListGroup } from "react-bootstrap";
import "./ProofOfDelivery.css?__remix_sideEffect__";

interface ProofOfDeliveryProps {
  deliveryImageUrl: string;
}

export const ProofOfDelivery: React.FC<ProofOfDeliveryProps> = ({ deliveryImageUrl }) => {
  const [hasError, setHasError] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const proofOfDeliveryClassNames = classNames(
    "p-3 text-center proof-of-delivery-li",
    (!isLoaded || hasError) && "hidden"
  );

  if (hasError) {
    return null;
  }

  return (
    <Suspense>
      <ListGroup.Item className={proofOfDeliveryClassNames}>
        <img
          onLoad={() => setIsLoaded(true)}
          onError={() => setHasError(true)}
          src={deliveryImageUrl}
          alt="Proof of delivery"
          className="proof-of-delivery-image" />

      </ListGroup.Item>
    </Suspense>);

};