import { isAfter, parseISO } from "date-fns";
import { flatten } from "lodash/fp";
import { PublicOrderSummary } from "~/common/types";

export const latestDate = (prevDate?: Date, currDate?: Date) => {
  if (!prevDate || !currDate) {
    return currDate;
  }
  const prev = new Date(prevDate);
  const curr = new Date(currDate);

  return isAfter(parseISO(prev.toISOString()), parseISO(curr.toISOString())) ? prevDate : currDate;
};

const filterShipmentErrors = (shipments: PublicOrderSummary["shipments"]) =>
  shipments.filter((shipment) => !shipment.error);

export const getLatestEstimatedTimes = (shipments: any) =>
  filterShipmentErrors(shipments).reduce(
    (dates, shipment) => ({
      estimatedDeliveryTime: latestDate(dates.estimatedDeliveryTime, shipment.estimatedDeliveryTime),
      estimatedShipTime: latestDate(dates.estimatedShipTime, shipment.estimatedShipTime),
    }),
    {
      estimatedDeliveryTime: undefined as Date | undefined,
      estimatedShipTime: undefined as Date | undefined,
    }
  );

export const getPackages = (shipments: PublicOrderSummary["shipments"]) => {
  const filteredShipments = filterShipmentErrors(shipments);
  const packages = filteredShipments.map((shipment) => shipment.packages);
  return flatten(packages);
};

export const getLatestPackageTimes = (shipments: any) =>
  getPackages(shipments).reduce(
    (dates, shipmentPackage) => ({
      packageShipTime: latestDate(dates.packageShipTime, shipmentPackage?.shipTime),
      packageDeliverTime: latestDate(dates.packageDeliverTime, shipmentPackage?.deliveryTime),
    }),
    {
      packageShipTime: undefined as Date | undefined,
      packageDeliverTime: undefined as Date | undefined,
    }
  );
