import { ParcelTrackingEvent } from "@deliverr/parcel-client";
import { isBefore } from "date-fns";
import { head, pick } from "lodash";
import orderBy from "lodash/orderBy";
import { getLatestEstimatedTimes } from "~/common/helpers/OrderEvents/helpers/getLatestEstimatedTimes";
import {
  OrderEvent,
  OrderEventType,
  TrackingDetailEvent,
  TrackingDetailResponse,
  TrackingEventStatus,
} from "~/common/types";
import { formatAddress } from "~/common/utils/address/formatAddress";
import { getEventString } from "~/common/utils/order/getEventString";
import { ParcelTrackingEventTypes } from "~/unified-tracking/utils/getStatusFromUnifiedTrackingDetail";
import { TimelineItem } from "./Timeline";

type EstimatedTimes = ReturnType<typeof getLatestEstimatedTimes>;

export const estimatedTitles = {
  DELIVERED: "Estimated delivery",
  SHIPPED: "Estimated shipping",
  IN_TRANSIT: "Estimated shipping",
};

const estimatedOrderKeys = {
  estimatedShipTime: OrderEventType.SHIPPED,
  estimatedDeliveryTime: OrderEventType.DELIVERED,
};

const estimatedTrackingKeys = {
  estimatedShipTime: TrackingEventStatus.IN_TRANSIT,
  estimatedDeliveryTime: TrackingEventStatus.DELIVERED,
};

const locationDisabledTypes = [
  ParcelTrackingEventTypes.Created,
  ParcelTrackingEventTypes.PickedUpFromOrigin,
  ParcelTrackingEventTypes.ArrivedAtOriginPort,
  ParcelTrackingEventTypes.DepartedFromOriginPort,
  ParcelTrackingEventTypes.Repurchased,
  ParcelTrackingEventTypes.Cancelled,
];

const isLocationEnabled = (eventType: ParcelTrackingEventTypes | undefined) => {
  if (eventType) {
    return !locationDisabledTypes.includes(eventType);
  }

  return true;
};

// Add in estimated events if actual events haven't happened yet.
const filterEstimatedItems = (
  latestTime: Date | undefined,
  getEstimatedType: (key: string) => string,
  estimatedEvents?: EstimatedTimes
) => {
  return estimatedEvents
    ? Object.entries(estimatedEvents).reduce((acc, [key, value]) => {
        if (
          !value ||
          (latestTime && isBefore(new Date(value), new Date(latestTime))) ||
          isBefore(new Date(value), new Date())
        ) {
          return acc;
        }
        const type = getEstimatedType(key);
        const item = !!type
          ? {
              title: estimatedTitles[type],
              date: value,
              isCurrent: false,
              isInactive: true,
            }
          : null;
        return item ? [...acc, item] : acc;
      }, [] as TimelineItem[])
    : [];
};

// Tracking events are not available.  Fallback to OrderEvents.
export const getOrderEventTimelineItems = (orderEvents: OrderEvent[], estimatedEvents?: EstimatedTimes) => {
  const sortedEvents = orderBy(orderEvents, "time", "desc");
  const latestTime = head(sortedEvents)?.time;
  const completedEvents = sortedEvents.map(({ type, time }, idx) => {
    return {
      title: getEventString(type),
      date: time,
      isCurrent: idx === 0,
      isInactive: false,
    };
  }) as TimelineItem[];

  // Check if this estimated type of event has already happened
  const getEstimatedType = (key: string) => {
    const type = estimatedOrderKeys[key];

    if (!sortedEvents.find((evt) => evt.type === type)) {
      return type;
    }
  };

  const estimatedItems = filterEstimatedItems(latestTime, getEstimatedType, estimatedEvents);

  return [...estimatedItems, ...completedEvents];
};

export const getTrackingTimelineItems = (
  trackingEvents: (TrackingDetailEvent | ParcelTrackingEvent)[],
  estimatedEvents?: EstimatedTimes
) => {
  const sortedEvents = orderBy(trackingEvents, "eventTime", "desc");
  const latestTime = head(sortedEvents)?.eventTime;
  const completedEvents = sortedEvents.map((event, idx) => {
    const { message, location, eventTime } = event;
    const eventType = "eventType" in event ? event.eventType : undefined;
    return {
      title: message,
      body: location && isLocationEnabled(eventType) ? formatAddress(location) : "",
      date: eventTime,
      isCurrent: idx === 0,
      isInactive: false,
    };
  }) as TimelineItem[];

  // Check if this estimated type of event has already happened
  const getEstimatedType = (key: string) => {
    const type = estimatedTrackingKeys[key];

    if (
      !sortedEvents.find(
        (evt) => ("status" in evt && evt.status === type) || ("eventType" in evt && evt.eventType === type)
      )
    ) {
      return type;
    }
  };

  const estimatedItems = filterEstimatedItems(latestTime, getEstimatedType, estimatedEvents);

  return [...estimatedItems, ...completedEvents];
};

export const getTimelineItems = (
  trackingDetail: TrackingDetailResponse,
  estimatedTimes: EstimatedTimes,
  orderEvents: OrderEvent[]
) => {
  console.log("getTimelineItems", { trackingDetail, history: trackingDetail?.trackingHistory });
  if (trackingDetail && trackingDetail.trackingHistory) {
    const { trackingHistory } = trackingDetail;
    const trackingEstimate = pick(trackingDetail, ["estimatedDeliveryTime"]);
    const trackingEstimates = { ...estimatedTimes, ...trackingEstimate };

    return getTrackingTimelineItems(trackingHistory, trackingEstimates);
  }
  console.log("falling back to order events");

  return getOrderEventTimelineItems(orderEvents, estimatedTimes);
};
