import { DeliverrAddress } from "@deliverr/commons-objects";
import { capitalize, isString, lowerCase, upperFirst } from "lodash/fp";

/* Very simple approach to handling the specific case of Algolia-highlighted results
   where there's an HTML tag at the beginning of the word */
// eslint-disable-next-line no-useless-escape
const LEADING_TAG_REGEX = /^(\<\w+\>)?(.*)/;
export const capitalizeWordHtml = (html) => {
  const [tag, word] = html.match(LEADING_TAG_REGEX);
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${tag || ""}${capitalize(word)}`;
};

const EM_TAG_REGEX = /^<em>|<\/em>/g;
/**
 * @deprecated Prefer `removeAllEmTags` when possible.
 *    This function has some subtle legacy behavior in the fact that it needs to handle
 *    numbers in some edge cases, and attempting to fix it can break the UI in places
 *    that the TS compiler will not catch. Ideally you should only resort to using this
 *    function if the raw value you are looking for is otherwise unavailable.
 */
export const removeEmTag = (str: string | number) => {
  return str.toString().replace(EM_TAG_REGEX, "");
};

const ALL_EM_TAGS_REGEX = /<\/?em>/g;
/**
 * Removes all opening and closing <em> tags from anywhere in the string.
 * For safety's sake, this should ONLY take strings; if you need to pass a non-string value
 * to it (e.g. a numeric ID), you should stringify the value before calling the function.
 * Ideally you should only resort to using this function if the raw value you are looking
 * for is otherwise unavailable.
 * @param str
 */
export const removeAllEmTags = (str: string): string => {
  return str.replace(ALL_EM_TAGS_REGEX, "");
};

// replaces invalid filename chars
export const createFilename = (name: string, extension: string) =>
  name.replace(/([^a-z0-9 _-]+)/gi, "_").concat(`.${extension}`);

export const formatTableItem = (item?: string | number) => (item ? item.toString() : "―");

export const formatAddressInline = (address: DeliverrAddress, provider?: string): string => {
  const { name, street1, street2, city, state, zip } = address;
  const nameField = provider ? `${provider} c/o Deliverr` : name;
  return `${nameField} ${street1} ${street2 ? street2 + " " : ""}${city}, ${state} ${zip}`;
};

// https://app.asana.com/0/inbox/460396085365525/1163125715260586/1163126989108766
// Remove state name from autocomplete input in the form of "ST - State"
export function filterOutState(stateInput: string): string {
  // eslint-disable-next-line no-extra-boolean-cast
  return Boolean(stateInput.match(/[A-Za-z]{2} - [A-Za-z]+$/)) ? stateInput.replace(/ - [A-Za-z]+$/, "") : stateInput;
}

export const coaleseBool = (value: string | boolean) => (isString(value) ? value === "true" : value);

// trim also line spaces
export const trimAll = (value: string) => value.trim().replace(/\\n/g, "");

// Replace any dashes or underscores with space,
// with the first letter of each word in uppercase and the rest in lowercase.
export const formatListing = (value: string) =>
  value
    .replace(/[-_]/g, " ")
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");

export const removeHtmlFromString = (value: string) => value.replace(/<\/?[^>]+(>|$)/g, "");

export const emailToSellerId = (value: string) => value.toLowerCase().replace(/[^A-Za-z0-9]/g, "");

export const toTitleCase = (words: string): string => {
  return words
    .split(" ")
    .map((word) => upperFirst(lowerCase(word)))
    .join(" ");
};

// based in: https://community.shopify.com/c/Shopify-Design/Ordinal-Number-in-javascript-1st-2nd-3rd-4th/m-p/72156
export function getNumberWithOrdinal(value: string | number): string {
  const normalizedValue = String(value).trim();
  const suffix = ["th", "st", "nd", "rd"];
  const ordinal = parseInt(normalizedValue, 10) % 100;
  const selectedSuffix = /^\d+$/.test(normalizedValue)
    ? suffix[(ordinal - 20) % 10] || suffix[ordinal] || suffix[0]
    : "";

  return `${normalizedValue}${selectedSuffix}`;
}

// Adds "s/es" if value is not 1
export function plural(text: string, value: number, es?: boolean): string {
  const pluralCharacters = es ? "es" : "s";
  return `${text}${Math.abs(value) !== 1 ? pluralCharacters : ""}`;
}

// US zip must be in the format NNNNN or NNNNN-NNNN (5 numbers or 5 numbers with a dash followed by 4 numbers)
export const validZipUS = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

// Canada zip must be in the format ANANAN (A = letter, N = number) with optional space or dash in between
// Postal codes do not include the letters D, F, I, O, Q or U,
// and the first position also does not make use of the letters W or Z
export const validZipCanada = /^[ABCEGHJ-NPRSTVXY][\d][ABCEGHJ-NPRSTV-Z](\s|-?)[\d][ABCEGHJ-NPRSTV-Z][\d]$/i;

export const validateZip = (zip: string, patterns: RegExp[]) => patterns.some((pattern) => pattern.test(zip.trim()));
