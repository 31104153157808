import { MarketplaceId } from "@deliverr/commons-objects/lib/Marketplace";
import { Marketplace } from "~/common/types";
import { parseMarketplaceId } from "../common/MarketplaceUtils";
import { EASTERN_TZ, PACIFIC_TZ } from "./TimeZone";

export const getShipTimeTz = (
  destinationTz?: string,
  marketId?: MarketplaceId,
  originalPromiseShipByTime?: string | Date
) => {
  if (!originalPromiseShipByTime) {
    return destinationTz ?? EASTERN_TZ;
  }

  if (!marketId) {
    return PACIFIC_TZ;
  }

  const name = parseMarketplaceId(marketId);

  if (name === Marketplace.AMAZON || name === Marketplace.EBAY) {
    return PACIFIC_TZ;
  }

  if (name === Marketplace.WALMART) {
    return EASTERN_TZ;
  }

  return PACIFIC_TZ;
};

export const getDeliveryTimeTz = (destinationTz?: string) => destinationTz ?? EASTERN_TZ;
