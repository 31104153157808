import { datadogRum } from "@datadog/browser-rum";
import { useParams } from "@remix-run/react";
import { classNames } from "@shopify/css-utilities";
import { AlphaStack, Divider, Inline, Text, TextProps } from "@shopify/polaris";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { Client } from "react-hydration-provider";
import { CarrierOnlyEstimationData } from "~/common/types/CarrierOnlyEstimationData";
import { DateFormat, parseDate } from "~/common/utils/date";
import { estimatedTitles } from "./getTimelineItems";
import "./Timeline.css?__remix_sideEffect__";

export interface TimelineItem {
  title: string;
  body?: string;
  date?: Date;
  disconnected?: boolean;
  isInactive?: boolean;
  isCurrent?: boolean;
  onClick?: () => void;
}

interface TimelineProps {
  items: TimelineItem[];
  destinationTz: string;
  carrierOnlyEstimationData?: CarrierOnlyEstimationData;
}

const DEFAULT_MAX_ITEMS = 4;

export const Timeline = ({ items, destinationTz, carrierOnlyEstimationData }: TimelineProps) => {
  const [showAllItems, setShowAllItems] = useState(false);
  const previousItems = items.length - DEFAULT_MAX_ITEMS;
  const hasMore = previousItems > 1;
  const showingItems = showAllItems || !hasMore ? items : items.slice(0, DEFAULT_MAX_ITEMS);
  const showMoreItem = {
    title: `Show ${previousItems} previous updates`,
    onClick: () => {
      datadogRum.addAction("user_show_timeline_items");
      setShowAllItems(true);
    }
  } as TimelineItem;
  const showLessItem = {
    title: `Show fewer updates`,
    onClick: () => {
      datadogRum.addAction("user_hide_timeline_items");
      setShowAllItems(false);
    }
  } as TimelineItem;
  const params = useParams();

  const onError = (error: Error, info: any) => {
    datadogRum.addError(error, { params, msg: "Timeline error", info });
  };

  const moreLessItem = showAllItems ? showLessItem : showMoreItem;
  const timelineItems = hasMore ? [...showingItems, moreLessItem] : showingItems;

  const lastConnectedItemIndex = timelineItems.findIndex((item, index) => {
    return !item.disconnected && (timelineItems[index + 1]?.disconnected || index === timelineItems.length - 1);
  });

  const getTimelineRow = (item: TimelineItem, index: number) => {
    const { body, disconnected = false, title, isInactive, isCurrent, onClick } = item;
    const isLastItem = lastConnectedItemIndex === index;
    const key = `${title || "item"}-${index}`;

    const markerClasses = classNames(
      "timeline-marker",
      isInactive && "timeline-marker-inactive",
      isCurrent && "timeline-marker-current"
    );

    const markerContainerClasses = classNames(
      "timeline-marker-container",
      isInactive && "timeline-connector-inactive",
      !disconnected && "timeline-connector",
      isLastItem && "timeline-short-connector"
    );

    const detailsContainerClasses = classNames(
      "timeline-details-container",
      disconnected && "timeline-disconnected-details-container"
    );

    const inactiveColorTextProperty: Partial<TextProps> | null = isInactive ?
    {
      color: "subdued"
    } :
    null;

    const dateTimeMarkup = item.date ?
    <div>
        <Client>
          <AlphaStack gap="0" align="end">
            <Text as="span" fontWeight="semibold" variant="bodyMd" {...inactiveColorTextProperty}>
              {parseDate(item.date, DateFormat.MonthDay, destinationTz)}
            </Text>
            {!(item.title === estimatedTitles.DELIVERED) &&
          <Text as="span" variant="bodyMd" color="subdued">
                {parseDate(item.date, DateFormat.Time, destinationTz)}
              </Text>}

          </AlphaStack>
        </Client>
      </div> :
    null;

    const titleMarkup = onClick ?
    <Button variant="outline-subdued" onClick={onClick} className="border-0 p-0">
        <Text as="h2" variant="headingSm" fontWeight="semibold" {...inactiveColorTextProperty}>
          {title}
        </Text>
      </Button> :

    title;


    const titleBodyMarkup =
    <div className="flex flex-column align-items-start flex-wrap me-2" style={{ flex: "1" }}>
        <Text as="h2" variant="headingSm" fontWeight="semibold" {...inactiveColorTextProperty}>
          {titleMarkup}
        </Text>
        {body &&
      <Text as="span" variant="bodyMd" color="subdued">
            {body}
          </Text>}

      </div>;


    return (
      <div key={key} className="timeline-item-wrapper">
        <ErrorBoundary onError={onError} fallback={<div>Timeline unavailable</div>}>
          <div className={markerContainerClasses}>
            <div className={markerClasses} />
          </div>
          <div className={detailsContainerClasses}>
            <div className="timeline-first-row">
              <Inline gap="0" blockAlign="start" align="space-between">
                {titleBodyMarkup}
                {dateTimeMarkup}
              </Inline>
            </div>
            {isLastItem ? null : <Divider borderStyle="dark" />}
          </div>
        </ErrorBoundary>
      </div>);

  };

  return <div>{timelineItems.map(getTimelineRow)}</div>;
};