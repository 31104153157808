import upperFirst from "lodash/upperFirst";
import { OrderEventType } from "~/common/types";

const typeMap = {
  CREATED: "Order created",
  INVALID_ADDRESS: "Invalid address",
  INGESTION: "Ingestion",
  PROCESSING: "Processing",
  PARTIALLY_FULFILLED: "Partially fulfilled",
  SHIPPED: "Shipped",
  TRACKING_REPORTED: "Tracking reported",
  IN_TRANSIT: "In transit",
  REPLACEMENT_SHIPPED: "Replacement shipped",
  DELIVERED: "Delivered",
  CANCELLED: "Cancelled",
};

export const getEventString = (eventType: OrderEventType) => {
  const eventStr = typeMap[eventType];

  if (eventStr) {
    return eventStr;
  }
  return upperFirst(`${eventType}`.toLowerCase().replace("_", " "));
};
