import { FulfillmentPackageWithProductData, FulfillmentShipmentWithoutAddress } from "~/common/types";

type ShipmentAndPackage = {
  shipment: FulfillmentShipmentWithoutAddress | undefined;
  pkg: FulfillmentPackageWithProductData | undefined;
};

const findPackage = (shipment: FulfillmentShipmentWithoutAddress, trackingCode: string) =>
  shipment.packages.find((pkg) => pkg.trackingCode === trackingCode);

export const findShipmentAndPackage = (
  shipments: FulfillmentShipmentWithoutAddress[],
  trackingCode: string
): ShipmentAndPackage => {
  let returnVal: ShipmentAndPackage = { shipment: undefined, pkg: undefined };

  shipments.find((shipment) => {
    const pkg = findPackage(shipment, trackingCode);

    if (pkg) {
      returnVal = { shipment, pkg };
    }
  });

  return returnVal;
};
