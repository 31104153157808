import { includes, intersection, toUpper } from "lodash/fp";
import { Marketplace } from "~/common/types";
import { removeEmTag } from "./StringUtils";

interface MarketplaceItem {
  name: string;
  logo?: JSX.Element;
}

export const marketplaceConfig: Record<Marketplace, MarketplaceItem> = {
  [Marketplace.SHOPIFY]: {
    name: "Shopify",
  },
  [Marketplace.WALMART]: {
    name: "Walmart",
  },
  [Marketplace.EBAY]: {
    name: "eBay",
  },
  [Marketplace.WISH]: {
    name: "Wish",
  },
  [Marketplace.AMAZON]: {
    name: "Amazon",
  },
  [Marketplace.GOOGLE]: {
    name: "Google",
  },
  [Marketplace.DELIVERR]: {
    name: "Deliverr",
  },
  [Marketplace.FACEBOOK]: {
    name: "Facebook",
  },
  [Marketplace.BIGCOMMERCE]: {
    name: "BigCommerce",
  },
  [Marketplace.WOOCOMMERCE]: {
    name: "WooCommerce",
  },
  [Marketplace.ETSY]: {
    name: "Etsy",
  },
  [Marketplace.MAGENTO]: {
    name: "Magento",
  },
  [Marketplace.TARGET]: {
    name: "Target",
  },
  [Marketplace.NEWEGG]: {
    name: "NewEgg",
  },
  [Marketplace.INSTAGRAM]: {
    name: "Instagram",
  },
  [Marketplace.ARTEZA]: {
    name: "Arteza",
  },
  [Marketplace.OVERSTOCK]: {
    name: "Overstock",
  },
  [Marketplace.JCPENNEY]: {
    name: "JCPenney",
  },
  [Marketplace.JANE]: {
    name: "Jane",
  },
  [Marketplace.AIRSHOP]: {
    name: "Airshop",
  },
  [Marketplace.KROGER]: {
    name: "Kroger",
  },
  [Marketplace.SKUPREME]: {
    name: "Skupreme",
  },
  [Marketplace.UNKNOWN]: {
    name: "UNKNOWN",
  },
};

export const parseMarketplaceId = (marketplaceId) => {
  const taglessMarketplaceId = toUpper(removeEmTag(marketplaceId));
  const possibleMarketplaceNames = taglessMarketplaceId.split(" ");
  const marketplaces = Object.keys(Marketplace);
  const matchedMarketplace = intersection(marketplaces, possibleMarketplaceNames);

  if (matchedMarketplace.length > 0) {
    return matchedMarketplace[0];
  }

  return marketplaces.reduce((acc, val) => {
    if (includes(val, taglessMarketplaceId)) {
      acc = val;
    }

    if (!marketplaceConfig[acc]) {
      acc = Marketplace.UNKNOWN;
    }

    return acc;
  }, taglessMarketplaceId);
};
